let filter = document.querySelector('body.archive .select-options');
let container = document.querySelector('body.archive .col3');

if(filter) {
    let categories = filter.querySelectorAll('li a');
    categories.forEach(function(category, index){
        category.addEventListener('click', function(e) {
            e.preventDefault();
            categories.forEach(function(cat, index){
                cat.classList.remove('active');
            });
            category.classList.add('active');
            getPosts(category.getAttribute('data-slug'));
        });
    });
}


function getPosts(category) {
    let language;
    let lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    if(lang == 'en-US') {
        language = 'en';
    }
    else {
        language = 'no';
    }
    let data = new FormData();
    data.append('action', 'get_posts');
    data.append('category', category);
    data.append('lang', language);

    $.ajax({
        url: qualific_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (data) {
            console.error(data);
        },
        complete: function (data) {
            container.innerHTML = '';
            container.innerHTML = data.responseText;
        }
    });
}
window.addEventListener('load', (event) => {

    const button = document.querySelector(".search-button")
    const menusearch = document.querySelector(".menu-search")

if (button){
    button.onclick = function toggleMenu() {
        if (menusearch.matches('.show')) {
            menusearch.classList.remove('show');
        }
        else {
            menusearch.classList.add("show");
            
        }
    };
}
});
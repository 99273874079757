let articlesPreview = document.querySelector("section.articles-preview");

if (articlesPreview) {
  let container = articlesPreview.querySelector(".articles");

  const preventClick = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
  };

  let isDown = false;
  var isDragged = false;
  let startX;
  let startY;
  let scrollLeft;
  let scrollTop;
  let velX;
  let velY;

  container.addEventListener("mousedown", (e) => {
    isDown = true;
    setTimeout(function () {
      container.classList.add("active");
    }, 100);
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
    startY = e.pageY - container.offsetTop;
    scrollTop = container.scrollTop;
    cancelXMomentumTracking();
    cancelYMomentumTracking();
  });
  container.addEventListener("mouseleave", () => {
    isDown = false;
    setTimeout(function () {
      container.classList.remove("active");
    }, 50);
  });
  container.addEventListener("mouseup", () => {
    isDown = false;
    let elements = container.querySelectorAll("article");
    if (isDragged) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", preventClick);
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", preventClick);
      }
    }
    setTimeout(function () {
      container.classList.remove("active");
    }, 50);
    isDragged = false;
    // Start a frame loop to continue drag momentum
    beginXMomentumTracking();
    beginYMomentumTracking();
  });
  container.addEventListener("mousemove", (e) => {
    if (!isDown) return;
    isDragged = true;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const y = e.pageY - container.offsetTop;
    const walkX = (x - startX) * 1.5;
    const walkY = (y - startY) * 1.5;

    // Store the previous scroll positions
    var prevScrollLeft = container.scrollLeft;
    var prevScrollTop = container.scrollTop;
    container.scrollLeft = scrollLeft - walkX;
    container.scrollTop = scrollTop - walkY;

    // Compare change in position to work out drag speed
    velX = container.scrollLeft - prevScrollLeft;
    velY = container.scrollTop - prevScrollTop;
  });

  let xMomentumID;
  function beginXMomentumTracking() {
    cancelXMomentumTracking();
    xMomentumID = requestAnimationFrame(xMomentumLoop);
  }
  function cancelXMomentumTracking() {
    cancelAnimationFrame(xMomentumID);
  }
  function xMomentumLoop() {
    if (Math.abs(velX) > 0.5) {
      container.scrollLeft += velX; // Apply the velocity to the scroll position
      velX *= 0.9; // Slow the velocity slightly
      xMomentumID = requestAnimationFrame(xMomentumLoop); // Keep looping
    }
  }

  let yMomentumID;
  function beginYMomentumTracking() {
    cancelYMomentumTracking();
    yMomentumID = requestAnimationFrame(yMomentumLoop);
  }
  function cancelYMomentumTracking() {
    cancelAnimationFrame(yMomentumID);
  }
  function yMomentumLoop() {
    if (Math.abs(velY) > 0.5) {
      container.scrollTop += velY; // Apply the velocity to the scroll position
      velY *= 0.9; // Slow the velocity slightly
      yMomentumID = requestAnimationFrame(yMomentumLoop); // Keep looping
    }
  }
}
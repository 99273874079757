window.addEventListener("scroll", colorMenu);

let menu = $("header");
let body = $("body");
let heroImage = $(".hero");
let whiteText = false;

var didScroll;
var lastScrollTop = 0;
var minScroll = 5;
var navbarHeight = $("header").outerHeight();
function colorMenu() {
  if (menu) {
   
    didScroll = true;

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled() {
            var st = $(this).scrollTop();

            if(Math.abs(lastScrollTop - st) <= minScroll)
                return;

            if (st > lastScrollTop && st > navbarHeight){
 
                menu.addClass('menu-transparent');
                setTimeout(function() {
                  if (menu.hasClass("menu-transparent")) {
                    menu.addClass("display");
                  }
            
             }, 300);

  
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                  menu.removeClass("display");
           setTimeout(function() {
            
            menu.removeClass('menu-transparent');
            menu.removeClass("display");
        }, 300);
                }
            }

            lastScrollTop = st;
        }
  }
}

import './_body-view-height';
import './header';
import './_menu-scroll';
import './_show-search';
import './_scroll-to';
//import './_slideshow.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}
import './_articles-preview.js';

import './_faq.js';
import './_newsletter.js';
import './_archive.js';